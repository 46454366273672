<table>
    <tr>
        <td>
            <table width="100%">
                <td class="vertical-align-middle">
                    <img class="logo" src="../../assets/img/logo.png"/>
                </td>
                <td align="right" class="vertical-align-middle">
                    <button mat-icon-button (click)="toggleSidebar()">
                        <mat-icon>close</mat-icon>
                    </button>
                </td>
            </table>
        </td>
    </tr>
    <tr>
        <td>
            <table>
                <tr>
                    <td>
                        <button mat-flat-button (click)="goToRequirementsPage()">
                            <mat-icon>list</mat-icon>
                            {{ 'requirements' | translate }}
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button mat-flat-button (click)="goToRestaurantPage()">
                            <mat-icon>table_restaurant</mat-icon>
                            {{ 'how_it_works' | translate }}: {{ 'business_type_restaurant' | translate }}
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button mat-flat-button (click)="goToFoodTruckPage()">
                            <mat-icon>fire_truck</mat-icon>
                            {{ 'how_it_works' | translate }}: {{ 'business_type_foodtruck' | translate }}
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button mat-flat-button (click)="goToPricingPage()">
                            <mat-icon>paid</mat-icon>
                            {{ 'pricing' | translate }}
                        </button>
                    </td>
                </tr>
                <tr>
                    <td>
                        <button mat-fab extended class="palette-background-color-red" (click)="goToBusinessWeb()">
                            <mat-icon>monitor</mat-icon>
                            {{ 'monitor_orders' | translate }}
                        </button>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
