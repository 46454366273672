import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  textRequiredFieldErrorMessage: string;
  textInvalidValueErrorMessage: string;

  constructor(
      private translateService: TranslateService) { 
    // Localized texts are initialized here because it is very likely that the observable
    // returned by the translate service will be completed before these texts are actually used.
    // This reduces code boilerplate.
    this.textRequiredFieldErrorMessage = '';
    this.textInvalidValueErrorMessage = '';
    this.updateTexts();
    this.translateService.onLangChange.subscribe((params: LangChangeEvent) => {
      this.updateTexts();
    });
  }

  public getFieldErrorMessage(field: FormControl): string {
    if (field.hasError('required')) {
      return this.textRequiredFieldErrorMessage;
    } else if (field.invalid) {
      return this.textInvalidValueErrorMessage;
    }
    return '';
  }

  private updateTexts(): void {
    this.translateService.get('error_required_value').subscribe(localizedText => {
      this.textRequiredFieldErrorMessage = localizedText;
    });
    this.translateService.get('error_invalid_value').subscribe(localizedText => {
      this.textInvalidValueErrorMessage = localizedText;
    });
  }
}
