<header [ngClass]="getCoverImageClassName()" #headerRef>
    <!--
        We added the app header inside the cover image so the height of
        the cover image matches the height of the screen, so when the user
        scrolls down the immediate content that appears after the cover
        image is the home page content.
    -->
    <app-header></app-header>
    <div class="cover-image-content">
        <!-- <div class="large-vertical-space"></div> -->
        <table width="100%" height="100%">
            <!--
            <tr>
                <td align="center">
                    <span [ngClass]="deviceService.ifMobile('cover-image-legend-mobile').orElse('cover-image-legend')">
                        {{ 'header_title' | translate }}
                    </span>
                </td>
            </tr>
            <tr>
                <td align="center">
                    <br>
                </td>
            </tr>
            <tr>
                <td align="center">
                    <span [ngClass]="deviceService.ifMobile('cover-image-description-mobile').orElse('cover-image-description')">
                        {{ 'header_subtitle' | translate }}
                    </span>
                </td>
            </tr>
            <tr>
                <td>
                    <br>
                    <br>
                </td>
            </tr>
            -->
            <tr>
                <td width="50%" height="50%">&nbsp;</td>
                <td width="50%" height="50%">&nbsp;</td>
            </tr>
            <tr>
                <td width="50%" height="50%">&nbsp;</td>
                <td  width="50%" height="50%" align="center">
                    <div [ngClass]="deviceService.ifMobile('cover-image-button-mobile').orElse('cover-image-button')">
                        <button mat-fab extended class="palette-background-color-red"
                                (click)="scrollToElement(requestDemoSectionRef)">
                            {{ 'request_demo' | translate }}
                        </button>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <div class="palette-background-color-light-grey">
        <br>
        &nbsp;
        <mat-icon svgIcon="facebook" (click)="navigationService.goToFacebook()" class="link-like"></mat-icon>
        &nbsp;
        <mat-icon svgIcon="instagram" (click)="navigationService.goToInstagram()" class="link-like"></mat-icon>
        &nbsp;
        <mat-icon svgIcon="youtube" (click)="navigationService.goToYouTube()" class="link-like"></mat-icon>
        &nbsp;
        <mat-icon svgIcon="twitter" (click)="navigationService.goToTwitter()" class="link-like"></mat-icon>
        &nbsp;
        <mat-icon svgIcon="linkedin" (click)="navigationService.goToLinkedin()" class="link-like"></mat-icon>
    </div>
</header>

<app-floating-button icon="expand_less"
        (click)="scrollToElement(headerRef)"
        *ngIf="isGoToTopButtonVisible">
</app-floating-button>

<div class="palette-background-color-light-grey">
    <br>
    <div class="palette-background-color-white rounded-borders-top">
        <br>
        <app-title>
            {{ 'our_solution' | translate }}
        </app-title>
        <br>
        <table width="100%">
            <tr>
                <td align="center">
                    <div [ngClass]="deviceService.ifMobile('waiternow-video-mobile').orElse('waiternow-video')" align="center">
                        <div class="iframe-container" *ngIf="!isSpanish()">
                            <iframe src="https://www.youtube.com/embed/huXXCTS_xT4"></iframe>
                        </div>
                        <div class="iframe-container" *ngIf="isSpanish()">
                            <iframe src="https://www.youtube.com/embed/addBF8TvULU"></iframe>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <br>
    </div>
</div>

<div class="palette-background-color-white">
    <br>
    <div class="palette-background-color-light-grey rounded-borders-top">
        <br>
        <app-title>
            {{ 'why_waiternow' | translate }}
        </app-title>
        <table align="center" class="advantage-table" *ngIf="!deviceService.isMobile()">
            <tr align="center">
                <td width="33%">{{ 'no_lines' | translate }}</td>
                <td width="33%">{{ 'no_table_number_holders_nor_pagers' | translate }}</td>
                <td width="33%">{{ 'no_subscriptions' | translate }}</td>
            </tr>
            <tr align="center">
                <td width="33%"><img style="width:80%; height:auto" src="../../assets/img/no-lines.png"></td>
                <td width="33%"><img style="width:80%; height:auto" src="../../assets/img/no-table-number-holders-nor-pagers.png"></td>
                <td width="33%"><img style="width:80%; height:auto" src="../../assets/img/no-subscriptions.png"></td>
            </tr>
        </table>
        <table width="100%" class="advantage-table-mobile" *ngIf="deviceService.isMobile()">
            <tr>
                <td align="center">{{ 'no_lines' | translate }}</td>
            </tr>
            <tr>
                <td align="center"><img style="width:70%; height:auto" src="../../assets/img/no-lines.png"></td>
            </tr>
            <tr>
                <td align="center">{{ 'no_table_number_holders_nor_pagers' | translate }}</td>
            </tr>
            <tr>
                <td align="center"><img style="width:70%; height:auto" src="../../assets/img/no-table-number-holders-nor-pagers.png"></td>
            </tr>
            <tr>
                <td align="center">{{ 'no_subscriptions' | translate }}</td>
            </tr>
            <tr>
                <td align="center"><img style="width:70%; height:auto" src="../../assets/img/no-subscriptions.png"></td>
            </tr>
        </table>
    </div>
</div>

<div class="palette-background-color-light-grey">
    <br>
    <div #requestDemoSectionRef class="palette-background-color-white rounded-borders-top">
        <br>
        <app-title>
            {{ 'request_demo' | translate }}
        </app-title>
        <app-table-layout [columns]="deviceService.ifMobile(1).orElse(2)" [items]="2">
            <div #layout>
                <div *ngIf="!isSpanish()" align="center">
                    <img [ngClass]="deviceService.ifMobile('register-business-image-mobile').orElse('register-business-image')" src="../../assets/img/consumer-en-old.png">
                </div>
                <div *ngIf="isSpanish()">
                    <img [ngClass]="deviceService.ifMobile('register-business-image-mobile').orElse('register-business-image')" src="../../assets/img/consumer-es-old.png">
                </div>
            </div>
            <div #layout>
                <table [ngClass]="deviceService.ifMobile('form-mobile').orElse('form')" align="center">
                    <tr><td>&nbsp;</td></tr>
                    <tr>
                        <td>
                            <mat-form-field class="form-field">
                                <mat-label>{{ 'name' | translate }}</mat-label>
                                <input matInput class="input" [formControl]="nameFormControl" required>
                                <mat-error *ngIf="nameFormControl.invalid">{{formService.getFieldErrorMessage(nameFormControl)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="form-field">
                                <mat-label>{{ 'email' | translate }}</mat-label>
                                <input matInput class="input" placeholder="email@example.com" [formControl]="emailFormControl" required>
                                <mat-error *ngIf="emailFormControl.invalid">{{formService.getFieldErrorMessage(emailFormControl)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="form-field">
                                <mat-label>{{ 'phone' | translate }}</mat-label>
                                <input matInput class="input" [formControl]="phoneFormControl" required>
                                <mat-error *ngIf="phoneFormControl.invalid">{{formService.getFieldErrorMessage(phoneFormControl)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="form-field">
                                <mat-label>{{ 'business_name' | translate }}</mat-label>
                                <input matInput [formControl]="businessNameFormControl" required>
                                <mat-error *ngIf="businessNameFormControl.invalid">{{formService.getFieldErrorMessage(businessNameFormControl)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <mat-form-field class="form-field">
                                <mat-label>{{ 'business_address' | translate }}</mat-label>
                                <input matInput class="input" [formControl]="businessAddressFormControl" required>
                                <mat-error *ngIf="businessAddressFormControl.invalid">{{formService.getFieldErrorMessage(businessAddressFormControl)}}</mat-error>
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <td  align="center">
                            <!--
                                Make sure this button is not enabled unless we have all the information for the user and all the information is validated.
                                This button is being tracked by Google ads for conversions. Conversions are expensive, so we don't want this button to be
                                clicked in vain.
                                Google Ads scripts are added in index.html by the installation script, only for prod.
                                The google ads account also has a configuration to track clicks on a button with id:
                                registration-form-submit-button-id-for-Google-ads-conversion-tracking
                             -->
                            <button mat-flat-button class="button" (click)="submitBusinessRegistration()"
                                    id="registration-form-submit-button-id-for-Google-ads-conversion-tracking"
                                    [disabled]="emailFormControl.invalid || nameFormControl.invalid || businessNameFormControl.invalid || businessAddressFormControl.invalid">
                                {{ 'submit' | translate }}
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </app-table-layout>
    </div>
</div>
<br>
<app-footer></app-footer>
