<app-page>
    <app-title>
        {{ 'pricing' | translate }}
    </app-title>
    <mat-tab-group>
        <mat-tab [label]="'country_us' | translate">
            <table width="100%" *ngIf="!deviceService.isMobile()">
                <tr>
                    <td width="50%">
                        <app-stripe-pricing [country]="US"></app-stripe-pricing>
                    </td>
                    <td width="50%">
                        <app-waiter-now-pricing [country]="US"></app-waiter-now-pricing>
                    </td>
                </tr>
            </table>
            <table width="100%" *ngIf="deviceService.isMobile()">
                <tr>
                    <td>
                        <app-stripe-pricing [country]="US"></app-stripe-pricing>
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-waiter-now-pricing [country]="US"></app-waiter-now-pricing>
                    </td>
                </tr>
            </table>
        </mat-tab>
        <mat-tab [label]="'country_mexico' | translate">
            <table width="100%" *ngIf="!deviceService.isMobile()">
                <tr>
                    <td width="50%">
                        <app-stripe-pricing [country]="MEXICO"></app-stripe-pricing>
                    </td>
                    <td width="50%">
                        <app-waiter-now-pricing [country]="MEXICO"></app-waiter-now-pricing>
                    </td>
                </tr>
            </table>
            <table width="100%" *ngIf="deviceService.isMobile()">
                <tr>
                    <td>
                        <app-stripe-pricing [country]="MEXICO"></app-stripe-pricing>
                    </td>
                </tr>
                <tr>
                    <td>
                        <app-waiter-now-pricing [country]="MEXICO"></app-waiter-now-pricing>
                    </td>
                </tr>
            </table>
        </mat-tab>
    </mat-tab-group>
</app-page>
