import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProgressDialogComponent } from '../components/progress-dialog/progress-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ProgressDialogService {

  constructor(private dialog: MatDialog) { }

  /**
   * Opens a progress dialog.
   * 
   * @param message dialog message
   * @param title dialog title (optional)
   * @returns a method reference used to close the dialog
   */
  public open(message?: string, title?: string) : CloseDialog {
    const dialogRef = this.dialog.open(ProgressDialogComponent, {
      data: {message: message, title: title},
      autoFocus: true,
      hasBackdrop: true,
      disableClose: true,
    });
    return () => dialogRef.close();
  }
}

export interface CloseDialog {
  (): void;
}
