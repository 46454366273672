import { AfterViewInit, Component, HostListener, ViewChild } from '@angular/core';
import { SidebarService } from './specific/services/sidebar.service';
import { MatDrawer } from '@angular/material/sidenav';
import { DeviceService } from './general/services/device.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit  {

  // This is set after the view is initialized
  @ViewChild('drawerRef') drawer!: MatDrawer;

  constructor(
      private sidebarService: SidebarService,
      public deviceService: DeviceService,
      private matIconRegistry:MatIconRegistry,
      private domSanitzer:DomSanitizer) {
    this.regiterIcons();
  }

  ngAfterViewInit(): void {
    this.sidebarService.setSidebarToggler(() => this.drawer.toggle());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.deviceService.onResize(event);
  }

  private regiterIcons(): void {
    this.matIconRegistry.addSvgIcon(
      'facebook',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/facebook.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'instagram',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/instagram.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'linkedin',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/linkedin.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'twitter',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/twitter.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'youtube',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/youtube.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'flag-us',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/flag-us.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'flag-mx',
      this.domSanitzer.bypassSecurityTrustResourceUrl('assets/icons/flag-mx.svg')
    );
  }
}
