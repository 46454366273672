import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material/tabs';
import { PageComponent } from '../../components/page/page.component';
import { StripePricingComponent } from '../../components/stripe-pricing/stripe-pricing.component';
import { WaiterNowPricingComponent } from '../../components/waiter-now-pricing/waiter-now-pricing.component';
import { Country } from '../../../general/services/localization.service';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from 'src/app/general/services/device.service';
import { MatGridListModule } from '@angular/material/grid-list';
import { TitleComponent } from 'src/app/general/components/title/title.component';

@Component({
  selector: 'app-pricing-page',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTabsModule,
    PageComponent,
    StripePricingComponent,
    WaiterNowPricingComponent,
    MatGridListModule,
    TitleComponent
  ],
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.css']
})
export class PricingPageComponent {
  readonly US: Country = Country.Us;
  readonly MEXICO: Country = Country.Mexico;

  constructor(public deviceService: DeviceService){
  }
}
