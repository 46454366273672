/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.waiternow = (function() {

    /**
     * Namespace waiternow.
     * @exports waiternow
     * @namespace
     */
    var waiternow = {};

    waiternow.common = (function() {

        /**
         * Namespace common.
         * @memberof waiternow
         * @namespace
         */
        var common = {};

        /**
         * EmailRecipient enum.
         * @name waiternow.common.EmailRecipient
         * @enum {number}
         * @property {number} EMAIL_RECIPIENT_UNSPECIFIED=0 EMAIL_RECIPIENT_UNSPECIFIED value
         * @property {number} INFO=1 INFO value
         * @property {number} SYSTEM=2 SYSTEM value
         * @property {number} SUPPORT=3 SUPPORT value
         * @property {number} CONTACT=4 CONTACT value
         * @property {number} FEEDBACK=5 FEEDBACK value
         * @property {number} SALES=6 SALES value
         * @property {number} BILLING=7 BILLING value
         * @property {number} QR_CODE_SALES=8 QR_CODE_SALES value
         */
        common.EmailRecipient = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "EMAIL_RECIPIENT_UNSPECIFIED"] = 0;
            values[valuesById[1] = "INFO"] = 1;
            values[valuesById[2] = "SYSTEM"] = 2;
            values[valuesById[3] = "SUPPORT"] = 3;
            values[valuesById[4] = "CONTACT"] = 4;
            values[valuesById[5] = "FEEDBACK"] = 5;
            values[valuesById[6] = "SALES"] = 6;
            values[valuesById[7] = "BILLING"] = 7;
            values[valuesById[8] = "QR_CODE_SALES"] = 8;
            return values;
        })();

        common.SendEmailActionProto = (function() {

            /**
             * Properties of a SendEmailActionProto.
             * @memberof waiternow.common
             * @interface ISendEmailActionProto
             */

            /**
             * Constructs a new SendEmailActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SendEmailActionProto.
             * @implements ISendEmailActionProto
             * @constructor
             * @param {waiternow.common.ISendEmailActionProto=} [properties] Properties to set
             */
            function SendEmailActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SendEmailActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.ISendEmailActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto instance
             */
            SendEmailActionProto.create = function create(properties) {
                return new SendEmailActionProto(properties);
            };

            /**
             * Encodes the specified SendEmailActionProto message. Does not implicitly {@link waiternow.common.SendEmailActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.ISendEmailActionProto} message SendEmailActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendEmailActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SendEmailActionProto message, length delimited. Does not implicitly {@link waiternow.common.SendEmailActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.ISendEmailActionProto} message SendEmailActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendEmailActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SendEmailActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendEmailActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SendEmailActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendEmailActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SendEmailActionProto message.
             * @function verify
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SendEmailActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SendEmailActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto
             */
            SendEmailActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SendEmailActionProto)
                    return object;
                return new $root.waiternow.common.SendEmailActionProto();
            };

            /**
             * Creates a plain object from a SendEmailActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.SendEmailActionProto} message SendEmailActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SendEmailActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SendEmailActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SendEmailActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SendEmailActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SendEmailActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SendEmailActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SendEmailActionProto";
            };

            SendEmailActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IEmailAddressProto|null} [from] Request from
                 * @property {waiternow.common.EmailRecipient|null} [to] Request to
                 * @property {string|null} [subject] Request subject
                 * @property {string|null} [body] Request body
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SendEmailActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request from.
                 * @member {waiternow.common.IEmailAddressProto|null|undefined} from
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.from = null;

                /**
                 * Request to.
                 * @member {waiternow.common.EmailRecipient} to
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.to = 0;

                /**
                 * Request subject.
                 * @member {string} subject
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.subject = "";

                /**
                 * Request body.
                 * @member {string} body
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.body = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SendEmailActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                        $root.waiternow.common.EmailAddressProto.encode(message.from, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.to);
                    if (message.subject != null && Object.hasOwnProperty.call(message, "subject"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.subject);
                    if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.body);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SendEmailActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.from = $root.waiternow.common.EmailAddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.to = reader.int32();
                                break;
                            }
                        case 3: {
                                message.subject = reader.string();
                                break;
                            }
                        case 4: {
                                message.body = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.from != null && message.hasOwnProperty("from")) {
                        var error = $root.waiternow.common.EmailAddressProto.verify(message.from);
                        if (error)
                            return "from." + error;
                    }
                    if (message.to != null && message.hasOwnProperty("to"))
                        switch (message.to) {
                        default:
                            return "to: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                    if (message.subject != null && message.hasOwnProperty("subject"))
                        if (!$util.isString(message.subject))
                            return "subject: string expected";
                    if (message.body != null && message.hasOwnProperty("body"))
                        if (!$util.isString(message.body))
                            return "body: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendEmailActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.SendEmailActionProto.Request();
                    if (object.from != null) {
                        if (typeof object.from !== "object")
                            throw TypeError(".waiternow.common.SendEmailActionProto.Request.from: object expected");
                        message.from = $root.waiternow.common.EmailAddressProto.fromObject(object.from);
                    }
                    switch (object.to) {
                    default:
                        if (typeof object.to === "number") {
                            message.to = object.to;
                            break;
                        }
                        break;
                    case "EMAIL_RECIPIENT_UNSPECIFIED":
                    case 0:
                        message.to = 0;
                        break;
                    case "INFO":
                    case 1:
                        message.to = 1;
                        break;
                    case "SYSTEM":
                    case 2:
                        message.to = 2;
                        break;
                    case "SUPPORT":
                    case 3:
                        message.to = 3;
                        break;
                    case "CONTACT":
                    case 4:
                        message.to = 4;
                        break;
                    case "FEEDBACK":
                    case 5:
                        message.to = 5;
                        break;
                    case "SALES":
                    case 6:
                        message.to = 6;
                        break;
                    case "BILLING":
                    case 7:
                        message.to = 7;
                        break;
                    case "QR_CODE_SALES":
                    case 8:
                        message.to = 8;
                        break;
                    }
                    if (object.subject != null)
                        message.subject = String(object.subject);
                    if (object.body != null)
                        message.body = String(object.body);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.from = null;
                        object.to = options.enums === String ? "EMAIL_RECIPIENT_UNSPECIFIED" : 0;
                        object.subject = "";
                        object.body = "";
                    }
                    if (message.from != null && message.hasOwnProperty("from"))
                        object.from = $root.waiternow.common.EmailAddressProto.toObject(message.from, options);
                    if (message.to != null && message.hasOwnProperty("to"))
                        object.to = options.enums === String ? $root.waiternow.common.EmailRecipient[message.to] === undefined ? message.to : $root.waiternow.common.EmailRecipient[message.to] : message.to;
                    if (message.subject != null && message.hasOwnProperty("subject"))
                        object.subject = message.subject;
                    if (message.body != null && message.hasOwnProperty("body"))
                        object.body = message.body;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendEmailActionProto.Request";
                };

                return Request;
            })();

            SendEmailActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SendEmailActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SendEmailActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SendEmailActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendEmailActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SendEmailActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SendEmailActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendEmailActionProto.Response";
                };

                return Response;
            })();

            return SendEmailActionProto;
        })();

        common.GetVersionsActionProto = (function() {

            /**
             * Properties of a GetVersionsActionProto.
             * @memberof waiternow.common
             * @interface IGetVersionsActionProto
             */

            /**
             * Constructs a new GetVersionsActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetVersionsActionProto.
             * @implements IGetVersionsActionProto
             * @constructor
             * @param {waiternow.common.IGetVersionsActionProto=} [properties] Properties to set
             */
            function GetVersionsActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetVersionsActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {waiternow.common.IGetVersionsActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetVersionsActionProto} GetVersionsActionProto instance
             */
            GetVersionsActionProto.create = function create(properties) {
                return new GetVersionsActionProto(properties);
            };

            /**
             * Encodes the specified GetVersionsActionProto message. Does not implicitly {@link waiternow.common.GetVersionsActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {waiternow.common.IGetVersionsActionProto} message GetVersionsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetVersionsActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetVersionsActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetVersionsActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {waiternow.common.IGetVersionsActionProto} message GetVersionsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetVersionsActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetVersionsActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetVersionsActionProto} GetVersionsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetVersionsActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetVersionsActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetVersionsActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetVersionsActionProto} GetVersionsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetVersionsActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetVersionsActionProto message.
             * @function verify
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetVersionsActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetVersionsActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetVersionsActionProto} GetVersionsActionProto
             */
            GetVersionsActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetVersionsActionProto)
                    return object;
                return new $root.waiternow.common.GetVersionsActionProto();
            };

            /**
             * Creates a plain object from a GetVersionsActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {waiternow.common.GetVersionsActionProto} message GetVersionsActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetVersionsActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetVersionsActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetVersionsActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetVersionsActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetVersionsActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetVersionsActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetVersionsActionProto";
            };

            GetVersionsActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetVersionsActionProto
                 * @interface IRequest
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetVersionsActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetVersionsActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetVersionsActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetVersionsActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetVersionsActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetVersionsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetVersionsActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetVersionsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetVersionsActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetVersionsActionProto.Request)
                        return object;
                    return new $root.waiternow.common.GetVersionsActionProto.Request();
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetVersionsActionProto.Request";
                };

                return Request;
            })();

            GetVersionsActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetVersionsActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IVersionProto|null} [consumerVersion] Response consumerVersion
                 * @property {waiternow.common.IVersionProto|null} [businessWebVersion] Response businessWebVersion
                 * @property {waiternow.common.IVersionProto|null} [adminVersion] Response adminVersion
                 * @property {waiternow.common.IVersionProto|null} [supportVersion] Response supportVersion
                 * @property {waiternow.common.IVersionProto|null} [landingPageVersion] Response landingPageVersion
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetVersionsActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetVersionsActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response consumerVersion.
                 * @member {waiternow.common.IVersionProto|null|undefined} consumerVersion
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.consumerVersion = null;

                /**
                 * Response businessWebVersion.
                 * @member {waiternow.common.IVersionProto|null|undefined} businessWebVersion
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.businessWebVersion = null;

                /**
                 * Response adminVersion.
                 * @member {waiternow.common.IVersionProto|null|undefined} adminVersion
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.adminVersion = null;

                /**
                 * Response supportVersion.
                 * @member {waiternow.common.IVersionProto|null|undefined} supportVersion
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.supportVersion = null;

                /**
                 * Response landingPageVersion.
                 * @member {waiternow.common.IVersionProto|null|undefined} landingPageVersion
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.landingPageVersion = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetVersionsActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetVersionsActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.consumerVersion != null && Object.hasOwnProperty.call(message, "consumerVersion"))
                        $root.waiternow.common.VersionProto.encode(message.consumerVersion, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.businessWebVersion != null && Object.hasOwnProperty.call(message, "businessWebVersion"))
                        $root.waiternow.common.VersionProto.encode(message.businessWebVersion, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.adminVersion != null && Object.hasOwnProperty.call(message, "adminVersion"))
                        $root.waiternow.common.VersionProto.encode(message.adminVersion, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.supportVersion != null && Object.hasOwnProperty.call(message, "supportVersion"))
                        $root.waiternow.common.VersionProto.encode(message.supportVersion, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.landingPageVersion != null && Object.hasOwnProperty.call(message, "landingPageVersion"))
                        $root.waiternow.common.VersionProto.encode(message.landingPageVersion, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetVersionsActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetVersionsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetVersionsActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.consumerVersion = $root.waiternow.common.VersionProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.businessWebVersion = $root.waiternow.common.VersionProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.adminVersion = $root.waiternow.common.VersionProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.supportVersion = $root.waiternow.common.VersionProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.landingPageVersion = $root.waiternow.common.VersionProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetVersionsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.consumerVersion != null && message.hasOwnProperty("consumerVersion")) {
                        var error = $root.waiternow.common.VersionProto.verify(message.consumerVersion);
                        if (error)
                            return "consumerVersion." + error;
                    }
                    if (message.businessWebVersion != null && message.hasOwnProperty("businessWebVersion")) {
                        var error = $root.waiternow.common.VersionProto.verify(message.businessWebVersion);
                        if (error)
                            return "businessWebVersion." + error;
                    }
                    if (message.adminVersion != null && message.hasOwnProperty("adminVersion")) {
                        var error = $root.waiternow.common.VersionProto.verify(message.adminVersion);
                        if (error)
                            return "adminVersion." + error;
                    }
                    if (message.supportVersion != null && message.hasOwnProperty("supportVersion")) {
                        var error = $root.waiternow.common.VersionProto.verify(message.supportVersion);
                        if (error)
                            return "supportVersion." + error;
                    }
                    if (message.landingPageVersion != null && message.hasOwnProperty("landingPageVersion")) {
                        var error = $root.waiternow.common.VersionProto.verify(message.landingPageVersion);
                        if (error)
                            return "landingPageVersion." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetVersionsActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetVersionsActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetVersionsActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.consumerVersion != null) {
                        if (typeof object.consumerVersion !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.consumerVersion: object expected");
                        message.consumerVersion = $root.waiternow.common.VersionProto.fromObject(object.consumerVersion);
                    }
                    if (object.businessWebVersion != null) {
                        if (typeof object.businessWebVersion !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.businessWebVersion: object expected");
                        message.businessWebVersion = $root.waiternow.common.VersionProto.fromObject(object.businessWebVersion);
                    }
                    if (object.adminVersion != null) {
                        if (typeof object.adminVersion !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.adminVersion: object expected");
                        message.adminVersion = $root.waiternow.common.VersionProto.fromObject(object.adminVersion);
                    }
                    if (object.supportVersion != null) {
                        if (typeof object.supportVersion !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.supportVersion: object expected");
                        message.supportVersion = $root.waiternow.common.VersionProto.fromObject(object.supportVersion);
                    }
                    if (object.landingPageVersion != null) {
                        if (typeof object.landingPageVersion !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.landingPageVersion: object expected");
                        message.landingPageVersion = $root.waiternow.common.VersionProto.fromObject(object.landingPageVersion);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.consumerVersion = null;
                        object.businessWebVersion = null;
                        object.adminVersion = null;
                        object.supportVersion = null;
                        object.landingPageVersion = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.consumerVersion != null && message.hasOwnProperty("consumerVersion"))
                        object.consumerVersion = $root.waiternow.common.VersionProto.toObject(message.consumerVersion, options);
                    if (message.businessWebVersion != null && message.hasOwnProperty("businessWebVersion"))
                        object.businessWebVersion = $root.waiternow.common.VersionProto.toObject(message.businessWebVersion, options);
                    if (message.adminVersion != null && message.hasOwnProperty("adminVersion"))
                        object.adminVersion = $root.waiternow.common.VersionProto.toObject(message.adminVersion, options);
                    if (message.supportVersion != null && message.hasOwnProperty("supportVersion"))
                        object.supportVersion = $root.waiternow.common.VersionProto.toObject(message.supportVersion, options);
                    if (message.landingPageVersion != null && message.hasOwnProperty("landingPageVersion"))
                        object.landingPageVersion = $root.waiternow.common.VersionProto.toObject(message.landingPageVersion, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetVersionsActionProto.Response";
                };

                return Response;
            })();

            return GetVersionsActionProto;
        })();

        common.EmailAddressProto = (function() {

            /**
             * Properties of an EmailAddressProto.
             * @memberof waiternow.common
             * @interface IEmailAddressProto
             * @property {string|null} [email] EmailAddressProto email
             * @property {string|null} [name] EmailAddressProto name
             */

            /**
             * Constructs a new EmailAddressProto.
             * @memberof waiternow.common
             * @classdesc Represents an EmailAddressProto.
             * @implements IEmailAddressProto
             * @constructor
             * @param {waiternow.common.IEmailAddressProto=} [properties] Properties to set
             */
            function EmailAddressProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EmailAddressProto email.
             * @member {string} email
             * @memberof waiternow.common.EmailAddressProto
             * @instance
             */
            EmailAddressProto.prototype.email = "";

            /**
             * EmailAddressProto name.
             * @member {string} name
             * @memberof waiternow.common.EmailAddressProto
             * @instance
             */
            EmailAddressProto.prototype.name = "";

            /**
             * Creates a new EmailAddressProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.IEmailAddressProto=} [properties] Properties to set
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto instance
             */
            EmailAddressProto.create = function create(properties) {
                return new EmailAddressProto(properties);
            };

            /**
             * Encodes the specified EmailAddressProto message. Does not implicitly {@link waiternow.common.EmailAddressProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.IEmailAddressProto} message EmailAddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EmailAddressProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified EmailAddressProto message, length delimited. Does not implicitly {@link waiternow.common.EmailAddressProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.IEmailAddressProto} message EmailAddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EmailAddressProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EmailAddressProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EmailAddressProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EmailAddressProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.email = reader.string();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EmailAddressProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EmailAddressProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EmailAddressProto message.
             * @function verify
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EmailAddressProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates an EmailAddressProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto
             */
            EmailAddressProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.EmailAddressProto)
                    return object;
                var message = new $root.waiternow.common.EmailAddressProto();
                if (object.email != null)
                    message.email = String(object.email);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from an EmailAddressProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.EmailAddressProto} message EmailAddressProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EmailAddressProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.email = "";
                    object.name = "";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this EmailAddressProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.EmailAddressProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EmailAddressProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EmailAddressProto
             * @function getTypeUrl
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EmailAddressProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.EmailAddressProto";
            };

            return EmailAddressProto;
        })();

        common.OperationStatusProto = (function() {

            /**
             * Properties of an OperationStatusProto.
             * @memberof waiternow.common
             * @interface IOperationStatusProto
             * @property {boolean|null} [isSuccess] OperationStatusProto isSuccess
             * @property {boolean|null} [isFailure] OperationStatusProto isFailure
             * @property {string|null} [errorCode] OperationStatusProto errorCode
             * @property {string|null} [errorMessage] OperationStatusProto errorMessage
             */

            /**
             * Constructs a new OperationStatusProto.
             * @memberof waiternow.common
             * @classdesc Represents an OperationStatusProto.
             * @implements IOperationStatusProto
             * @constructor
             * @param {waiternow.common.IOperationStatusProto=} [properties] Properties to set
             */
            function OperationStatusProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OperationStatusProto isSuccess.
             * @member {boolean} isSuccess
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.isSuccess = false;

            /**
             * OperationStatusProto isFailure.
             * @member {boolean} isFailure
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.isFailure = false;

            /**
             * OperationStatusProto errorCode.
             * @member {string} errorCode
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.errorCode = "";

            /**
             * OperationStatusProto errorMessage.
             * @member {string} errorMessage
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.errorMessage = "";

            /**
             * Creates a new OperationStatusProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.IOperationStatusProto=} [properties] Properties to set
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto instance
             */
            OperationStatusProto.create = function create(properties) {
                return new OperationStatusProto(properties);
            };

            /**
             * Encodes the specified OperationStatusProto message. Does not implicitly {@link waiternow.common.OperationStatusProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.IOperationStatusProto} message OperationStatusProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OperationStatusProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.isSuccess != null && Object.hasOwnProperty.call(message, "isSuccess"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isSuccess);
                if (message.isFailure != null && Object.hasOwnProperty.call(message, "isFailure"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isFailure);
                if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.errorCode);
                if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.errorMessage);
                return writer;
            };

            /**
             * Encodes the specified OperationStatusProto message, length delimited. Does not implicitly {@link waiternow.common.OperationStatusProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.IOperationStatusProto} message OperationStatusProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OperationStatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OperationStatusProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OperationStatusProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OperationStatusProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.isSuccess = reader.bool();
                            break;
                        }
                    case 2: {
                            message.isFailure = reader.bool();
                            break;
                        }
                    case 3: {
                            message.errorCode = reader.string();
                            break;
                        }
                    case 4: {
                            message.errorMessage = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OperationStatusProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OperationStatusProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OperationStatusProto message.
             * @function verify
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OperationStatusProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.isSuccess != null && message.hasOwnProperty("isSuccess"))
                    if (typeof message.isSuccess !== "boolean")
                        return "isSuccess: boolean expected";
                if (message.isFailure != null && message.hasOwnProperty("isFailure"))
                    if (typeof message.isFailure !== "boolean")
                        return "isFailure: boolean expected";
                if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                    if (!$util.isString(message.errorCode))
                        return "errorCode: string expected";
                if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                    if (!$util.isString(message.errorMessage))
                        return "errorMessage: string expected";
                return null;
            };

            /**
             * Creates an OperationStatusProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto
             */
            OperationStatusProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.OperationStatusProto)
                    return object;
                var message = new $root.waiternow.common.OperationStatusProto();
                if (object.isSuccess != null)
                    message.isSuccess = Boolean(object.isSuccess);
                if (object.isFailure != null)
                    message.isFailure = Boolean(object.isFailure);
                if (object.errorCode != null)
                    message.errorCode = String(object.errorCode);
                if (object.errorMessage != null)
                    message.errorMessage = String(object.errorMessage);
                return message;
            };

            /**
             * Creates a plain object from an OperationStatusProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.OperationStatusProto} message OperationStatusProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OperationStatusProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.isSuccess = false;
                    object.isFailure = false;
                    object.errorCode = "";
                    object.errorMessage = "";
                }
                if (message.isSuccess != null && message.hasOwnProperty("isSuccess"))
                    object.isSuccess = message.isSuccess;
                if (message.isFailure != null && message.hasOwnProperty("isFailure"))
                    object.isFailure = message.isFailure;
                if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                    object.errorCode = message.errorCode;
                if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                    object.errorMessage = message.errorMessage;
                return object;
            };

            /**
             * Converts this OperationStatusProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OperationStatusProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OperationStatusProto
             * @function getTypeUrl
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OperationStatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.OperationStatusProto";
            };

            return OperationStatusProto;
        })();

        common.VersionProto = (function() {

            /**
             * Properties of a VersionProto.
             * @memberof waiternow.common
             * @interface IVersionProto
             * @property {google.protobuf.ITimestamp|null} [buildTime] VersionProto buildTime
             * @property {google.protobuf.ITimestamp|null} [releaseTime] VersionProto releaseTime
             * @property {number|null} [major] VersionProto major
             * @property {number|null} [minor] VersionProto minor
             * @property {number|null} [patch] VersionProto patch
             */

            /**
             * Constructs a new VersionProto.
             * @memberof waiternow.common
             * @classdesc Represents a VersionProto.
             * @implements IVersionProto
             * @constructor
             * @param {waiternow.common.IVersionProto=} [properties] Properties to set
             */
            function VersionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * VersionProto buildTime.
             * @member {google.protobuf.ITimestamp|null|undefined} buildTime
             * @memberof waiternow.common.VersionProto
             * @instance
             */
            VersionProto.prototype.buildTime = null;

            /**
             * VersionProto releaseTime.
             * @member {google.protobuf.ITimestamp|null|undefined} releaseTime
             * @memberof waiternow.common.VersionProto
             * @instance
             */
            VersionProto.prototype.releaseTime = null;

            /**
             * VersionProto major.
             * @member {number} major
             * @memberof waiternow.common.VersionProto
             * @instance
             */
            VersionProto.prototype.major = 0;

            /**
             * VersionProto minor.
             * @member {number} minor
             * @memberof waiternow.common.VersionProto
             * @instance
             */
            VersionProto.prototype.minor = 0;

            /**
             * VersionProto patch.
             * @member {number} patch
             * @memberof waiternow.common.VersionProto
             * @instance
             */
            VersionProto.prototype.patch = 0;

            /**
             * Creates a new VersionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {waiternow.common.IVersionProto=} [properties] Properties to set
             * @returns {waiternow.common.VersionProto} VersionProto instance
             */
            VersionProto.create = function create(properties) {
                return new VersionProto(properties);
            };

            /**
             * Encodes the specified VersionProto message. Does not implicitly {@link waiternow.common.VersionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {waiternow.common.IVersionProto} message VersionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VersionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.buildTime != null && Object.hasOwnProperty.call(message, "buildTime"))
                    $root.google.protobuf.Timestamp.encode(message.buildTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.releaseTime != null && Object.hasOwnProperty.call(message, "releaseTime"))
                    $root.google.protobuf.Timestamp.encode(message.releaseTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.major != null && Object.hasOwnProperty.call(message, "major"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.major);
                if (message.minor != null && Object.hasOwnProperty.call(message, "minor"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.minor);
                if (message.patch != null && Object.hasOwnProperty.call(message, "patch"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.patch);
                return writer;
            };

            /**
             * Encodes the specified VersionProto message, length delimited. Does not implicitly {@link waiternow.common.VersionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {waiternow.common.IVersionProto} message VersionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VersionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a VersionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.VersionProto} VersionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VersionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.VersionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.buildTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.releaseTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.major = reader.int32();
                            break;
                        }
                    case 4: {
                            message.minor = reader.int32();
                            break;
                        }
                    case 5: {
                            message.patch = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a VersionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.VersionProto} VersionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VersionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a VersionProto message.
             * @function verify
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VersionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.buildTime != null && message.hasOwnProperty("buildTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.buildTime);
                    if (error)
                        return "buildTime." + error;
                }
                if (message.releaseTime != null && message.hasOwnProperty("releaseTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.releaseTime);
                    if (error)
                        return "releaseTime." + error;
                }
                if (message.major != null && message.hasOwnProperty("major"))
                    if (!$util.isInteger(message.major))
                        return "major: integer expected";
                if (message.minor != null && message.hasOwnProperty("minor"))
                    if (!$util.isInteger(message.minor))
                        return "minor: integer expected";
                if (message.patch != null && message.hasOwnProperty("patch"))
                    if (!$util.isInteger(message.patch))
                        return "patch: integer expected";
                return null;
            };

            /**
             * Creates a VersionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.VersionProto} VersionProto
             */
            VersionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.VersionProto)
                    return object;
                var message = new $root.waiternow.common.VersionProto();
                if (object.buildTime != null) {
                    if (typeof object.buildTime !== "object")
                        throw TypeError(".waiternow.common.VersionProto.buildTime: object expected");
                    message.buildTime = $root.google.protobuf.Timestamp.fromObject(object.buildTime);
                }
                if (object.releaseTime != null) {
                    if (typeof object.releaseTime !== "object")
                        throw TypeError(".waiternow.common.VersionProto.releaseTime: object expected");
                    message.releaseTime = $root.google.protobuf.Timestamp.fromObject(object.releaseTime);
                }
                if (object.major != null)
                    message.major = object.major | 0;
                if (object.minor != null)
                    message.minor = object.minor | 0;
                if (object.patch != null)
                    message.patch = object.patch | 0;
                return message;
            };

            /**
             * Creates a plain object from a VersionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {waiternow.common.VersionProto} message VersionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VersionProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.buildTime = null;
                    object.releaseTime = null;
                    object.major = 0;
                    object.minor = 0;
                    object.patch = 0;
                }
                if (message.buildTime != null && message.hasOwnProperty("buildTime"))
                    object.buildTime = $root.google.protobuf.Timestamp.toObject(message.buildTime, options);
                if (message.releaseTime != null && message.hasOwnProperty("releaseTime"))
                    object.releaseTime = $root.google.protobuf.Timestamp.toObject(message.releaseTime, options);
                if (message.major != null && message.hasOwnProperty("major"))
                    object.major = message.major;
                if (message.minor != null && message.hasOwnProperty("minor"))
                    object.minor = message.minor;
                if (message.patch != null && message.hasOwnProperty("patch"))
                    object.patch = message.patch;
                return object;
            };

            /**
             * Converts this VersionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.VersionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VersionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for VersionProto
             * @function getTypeUrl
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VersionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.VersionProto";
            };

            return VersionProto;
        })();

        return common;
    })();

    return waiternow;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.seconds = reader.int64();
                            break;
                        }
                    case 2: {
                            message.nanos = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                var message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Timestamp
             * @function getTypeUrl
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Timestamp";
            };

            return Timestamp;
        })();

        return protobuf;
    })();

    return google;
})();

module.exports = $root;
