import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Country } from 'src/app/general/services/localization.service';
import { DeviceService } from '../../../general/services/device.service';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { HeadingComponent } from 'src/app/general/components/heading/heading.component';

interface PriceEntry {
  range: string;
  serviceFee: string;
}

@Component({
  selector: 'app-waiter-now-pricing',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatCardModule,
    MatTableModule,
    HeadingComponent
  ],
  templateUrl: './waiter-now-pricing.component.html',
  styleUrls: ['./waiter-now-pricing.component.css']
})
export class WaiterNowPricingComponent {
  @Input() country!: Country;

  pricesColumnNames: string[] = ['range', 'serviceFee'];
  pricesUs: PriceEntry[] = [
    // {range:'Subtotal < $25', serviceFee:'$0.25'},
    // {range:'$25 ≤ Subtotal < $50', serviceFee:'$0.50'},
    {range:'Subtotal < $50', serviceFee:'$0.50'},
    {range:'$50 ≤ Subtotal < $75', serviceFee:'$0.75'},
    {range:'$75 < Subtotal', serviceFee:'$1'}
  ];
  pricesMx: PriceEntry[] = [
    // {range:'Subtotal < $500', serviceFee:'$5'},
    // {range:'$500 ≤ Subtotal < $1,000', serviceFee:'$10'},
    {range:'Subtotal < $500', serviceFee:'$5'},
    {range:'$500 ≤ Subtotal < $1,000', serviceFee:'$7.50'},
    {range:'$1,000 < Subtotal', serviceFee:'$10'}
  ];

  constructor(
      public deviceService: DeviceService) {
  }

  public isUs() {
    return this.country == Country.Us;
  }

  public isMexico() {
    return this.country == Country.Mexico;
  }
}
