import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from 'src/app/general/services/device.service';
import { MatDividerModule } from '@angular/material/divider';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { SubtitleComponent } from 'src/app/general/components/subtitle/subtitle.component';

@Component({
  selector: 'app-food-truck-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    MatDividerModule,
    TitleComponent,
    SubtitleComponent
  ],
  templateUrl: './food-truck-page.component.html',
  styleUrls: ['./food-truck-page.component.css']
})
export class FoodTruckPageComponent {

  constructor(
      public deviceService: DeviceService) {
  }
}
