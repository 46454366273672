<table align="center" [ngClass]="deviceService.ifMobile('price-card-mobile').orElse('price-card')">
    <tr>
        <td align="center">
            <app-heading>
                {{ 'for_the_customer' | translate }}
            </app-heading>
        </td>
    </tr>
    <tr>
        <td width="100%" align="center" class="rounded-borders wn-orange-background wn-white">
            <table width="100%"  [ngClass]="deviceService.ifMobile('text-mobile').orElse('text')">
                <tr>
                    <td align="center">
                        <br>
                        <br>
                        {{ 'price_for_customer_line_1' | translate }}
                        <br>
                        {{ 'price_for_customer_line_2' | translate }}
                        <br>
                        <br>
                        {{ 'currency' | translate }}:
                        <span *ngIf="isUs()">USD</span>
                        <span *ngIf="isMexico()">MXN</span>
                        <br>
                        <br>
                        <table mat-table [dataSource]="isUs() ? pricesUs : pricesMx"
                                class="data-table">
                            <ng-container matColumnDef="range">
                                <th mat-header-cell *matHeaderCellDef class="wn-white">
                                    {{'subtotal' | translate}}
                                </th>
                                <td mat-cell *matCellDef="let priceEntry" class="wn-white">
                                    {{priceEntry.range}}
                                </td>
                            </ng-container>
                            <ng-container matColumnDef="serviceFee">
                                <th mat-header-cell *matHeaderCellDef class="wn-white">
                                    {{'service_fee' | translate}}
                                </th>
                                <td mat-cell *matCellDef="let priceEntry" class="wn-white">
                                    {{priceEntry.serviceFee}}
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="pricesColumnNames"></tr>
                            <tr mat-row *matRowDef="let row; columns: pricesColumnNames;"></tr>
                        </table>
                        <!-- Extra space required so the table doesn't overlap the bottom rounded borders -->
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
