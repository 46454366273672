<table align="center" [ngClass]="deviceService.ifMobile('price-card-mobile').orElse('price-card')">
    <tr>
        <td align="center">
            <app-heading>
                {{ 'for_the_business' | translate }}
            </app-heading>
        </td>
    </tr>
    <tr>
        <td align="center" class="rounded-borders wn-orange-background wn-white">
            <table [ngClass]="deviceService.ifMobile('text-mobile').orElse('text')">
                <tr>
                    <td align="center">
                        <br>
                        <br>
                        {{ 'only_credit_card_fee_line_1' | translate }}
                        <br>
                        {{ 'only_credit_card_fee_line_2' | translate }}:
                        <a href="https://stripe.com" target="_blank">Stripe</a>
                        <br>
                        <br>
                        <br>
                        <br>
                        <span align="center"
                                [ngClass]="deviceService.ifMobile('no-waiternow-fee-mobile').orElse('no-waiternow-fee')">
                            {{ 'waiternow_no_fee_for_business' | translate }}
                        </span>
                        <br>
                        <br>
                        <br>
                        <br>
                        {{ 'waiternow_no_fee_for_business_advantage_line_1' | translate }}
                        <br>
                        {{ 'waiternow_no_fee_for_business_advantage_line_2' | translate }}
                        <br>
                        <br>
                        <br>
                        <br>
                        <p align="center" [ngClass]="deviceService.ifMobile('stripe-price-mobile').orElse('stripe-price')"
                                *ngIf="isUs()">
                            2.9% + 30¢ USD
                        </p>
                        <p align="center" [ngClass]="deviceService.ifMobile('stripe-price-mobile').orElse('stripe-price')"
                                *ngIf="isMexico()">
                            3.6% + 3 MXN
                        </p>
                        <p align="center" *ngIf="isUs()">
                            {{ 'per_successful_charge_in' | translate }} {{ 'country_us' | translate }}
                        </p>
                        <p align="center" *ngIf="isMexico()">
                            {{ 'per_successful_charge_in' | translate }} {{ 'country_mexico' | translate }}
                        </p>
                        <p align="center" *ngIf="isUs()" [ngClass]="deviceService.ifMobile('text-small-mobile').orElse('text-small')">
                            {{ 'stripe_radar_charge' | translate: { fee: '2¢ USD' } }}
                        </p>
                        <p align="center" *ngIf="isMexico()" [ngClass]="deviceService.ifMobile('text-small-mobile').orElse('text-small')">
                            {{ 'stripe_radar_charge' | translate: { fee: '20¢ MXN' } }}
                        </p>
                        <br>
                        <mat-divider></mat-divider>
                        <br>
                        <p align="center" *ngIf="isUs()">
                            {{ 'check_latest_prices_at' | translate }} <a href="https://stripe.com/pricing" target="_blank">stripe.com/pricing</a>
                        </p>
                        <p align="center" *ngIf="isMexico()">
                            {{ 'check_latest_prices_at' | translate }} <a href="https://stripe.com/en-mx/pricing" target="_blank">stripe.com/en-mx/pricing</a>
                        </p>
                        <br>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
</table>
