import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { NavigationService } from '../../services/navigation.service';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [CommonModule, TranslateModule, PageComponent, MatButtonModule],
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css', '../../../../styles.css']
})
export class PageNotFoundComponent {

  constructor(
      private navigationService: NavigationService){
  }

  goToHomePage() {
    this.navigationService.goToHomePage();
  }
}
