import { Routes } from '@angular/router';
import { HomePageComponent } from './specific/pages/home-page/home-page.component';
import { PageNotFoundComponent } from './specific/pages/page-not-found/page-not-found.component';
import { FoodTruckPageComponent } from './specific/pages/food-truck-page/food-truck-page.component';
import { RestaurantPageComponent } from './specific/pages/restaurant-page/restaurant-page.component';
import { PricingPageComponent } from './specific/pages/pricing-page/pricing-page.component';
import { RequirementsPageComponent } from './specific/pages/requirements-page/requirements-page.component';

// See https://angular.io/guide/router

const routeConfig: Routes = [
    {
      path: '',
      component: HomePageComponent,
      title: 'Home page'
    },
    {
      path: 'pricing',
      component: PricingPageComponent,
      title: 'Pricing'
    },
    {
      path: 'requirements',
      component: RequirementsPageComponent,
      title: 'Requirements'
    },
    {
      path: 'foodtruck',
      component: FoodTruckPageComponent,
      title: 'Food truck'
    },
    {
      path: 'restaurant',
      component: RestaurantPageComponent,
      title: 'Restaurant'
    },
    {
      path: '**',
      component: PageNotFoundComponent,
      title: 'Page not found'
    }
  ];
  
export default routeConfig;
