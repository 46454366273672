import { Util } from 'src/app/general/util/util';
import * as proto from 'src/proto/compiled-protos';

interface SecondsAndNanos {
  seconds: number | null | undefined;
  nanos: number | null | undefined;
}

export class ProtoUtil {

  static protoToString(protoMessage: object | null | undefined) {
    if (!protoMessage) {
      return '';
    }
    return JSON.stringify(protoMessage);
  }

  static secondsAndNanosToMillis(secondsAndNanos: SecondsAndNanos) {
    let milliseconds = Util.safeNumber(secondsAndNanos.seconds) * 1000;
    milliseconds += Math.floor(Util.safeNumber(secondsAndNanos.nanos) / 1000_000);
    return milliseconds;
  }

  static secondsAndNanosFromMillis(millis: number): SecondsAndNanos {
    const seconds = Math.floor(millis / 1000);
    const remaining = millis % 1000;
    const nanos = remaining *1000_000;
    return { seconds: seconds, nanos: nanos };
  }

  static timestampProtoToMillis(timestamp: proto.google.protobuf.ITimestamp | null | undefined): number {
    if (!timestamp) {
      return 0;
    }
    return ProtoUtil.secondsAndNanosToMillis({ seconds: timestamp.seconds, nanos: timestamp.nanos});
  }
}
