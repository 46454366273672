import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from 'src/app/general/services/device.service';
import { TitleComponent } from 'src/app/general/components/title/title.component';

@Component({
  selector: 'app-requirements-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    TitleComponent
  ],
  templateUrl: './requirements-page.component.html',
  styleUrls: ['./requirements-page.component.css']
})
export class RequirementsPageComponent {

  constructor(public deviceService:DeviceService) {
  }
}
