import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { DeviceService } from '../../services/device.service';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-floating-button
//         icon="edit"
//         (click)="onCLick()">
// </app-floating-button>

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-floating-button',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.css', '../../../../styles.css']
})
export class FloatingButtonComponent {
  @Input() icon!: string;

  constructor(public deviceService: DeviceService) {
  }
}
