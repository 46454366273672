<app-page>
    <app-title align="center">
        {{ 'business_type_restaurant' | translate }}
    </app-title>
    <table align="center"
            [ngClass]="deviceService.ifMobile('container-mobile text-mobile').orElse('container text')">
        <tr>
            <td align="center" width="50%">
                <app-subtitle>
                    {{ 'customer_side' | translate }}
                </app-subtitle>
            </td>
            <td></td>
            <td align="center" width="50%">
                <app-subtitle>
                    {{ 'business_side' | translate }}
                </app-subtitle>
            </td>
        </tr>
        <tr>
            <td>
                <app-subtitle>
                    {{ 'step_1' | translate }}
                </app-subtitle>
                {{ 'step_scans_qr_code_and_places_order' | translate }}
            </td>
            <td class="vertical-dotted-line"></td>
            <td></td>
        </tr>
        <tr>
            <td align="center">
                <img src="../../assets/img/step-scan-qr-code.png" class="step-image">
            </td>
            <td class="vertical-dotted-line"></td>
            <td></td>
        </tr>
        <tr>
            <td><mat-divider></mat-divider></td>
            <td></td>
            <td><mat-divider></mat-divider></td>
        </tr>
        <tr>
            <td></td>
            <td class="vertical-dotted-line"></td>
            <td align="right">
                <app-subtitle>
                    {{ 'step_2' | translate }}
                </app-subtitle>
                {{ 'step_receives_order' | translate }}
            </td>
        </tr>
        <tr>
            <td></td>
            <td class="vertical-dotted-line"></td>
            <td align="center">
                <img src="../../assets/img/step-receives-order.png" class="step-image">
            </td>
        </tr>
        <tr>
            <td><mat-divider></mat-divider></td>
            <td></td>
            <td><mat-divider></mat-divider></td>
        </tr>
        <tr>
            <td></td>
            <td class="vertical-dotted-line"></td>
            <td align="right">
                <app-subtitle>
                    {{ 'step_3' | translate }}
                </app-subtitle>
                {{ 'step_prepares_order' | translate }}
            </td>
        </tr>
        <tr>
            <td></td>
            <td class="vertical-dotted-line"></td>
            <td align="center">
                <img src="../../assets/img/step-prepares-order.png" class="step-image">
            </td>
        </tr>
        <tr>
            <td><mat-divider></mat-divider></td>
            <td></td>
            <td><mat-divider></mat-divider></td>
        </tr>
        <tr>
            <td></td>
            <td class="vertical-dotted-line"></td>
            <td align="right">
                <app-subtitle>
                    {{ 'step_4' | translate }}
                </app-subtitle>
                {{ 'step_brings_order' | translate }}
            </td>
        </tr>
        <tr>
            <td></td>
            <td class="vertical-dotted-line"></td>
            <td align="center">
                <img src="../../assets/img/step-brings-order.png" class="step-image">
            </td>
        </tr>
        <tr>
            <td><mat-divider></mat-divider></td>
            <td></td>
            <td><mat-divider></mat-divider></td>
        </tr>
        <tr>
            <td>
                <app-subtitle>
                    {{ 'step_5' | translate }}
                </app-subtitle>
                {{ 'step_enjoys_food' | translate }}
            </td>
            <td class="vertical-dotted-line"></td>
            <td></td>
        </tr>
        <tr>
            <td align="center">
                <img src="../../assets/img/step-enjoys-food.png" class="step-image">
            </td>
            <td class="vertical-dotted-line"></td>
            <td></td>
        </tr>
    </table>
</app-page>
