<app-page>
    <app-title>
        {{ 'requirements' | translate }}
    </app-title>
    <br>
    <div align="center">
        <span class="bold-text">
            1. {{ 'requirement_tablet' | translate}}
        </span>
        <a href="https://play.google.com/store/apps/details?id=com.waiternow.business&hl=en_US&gl=US&pli=1" target="_blank">
            ({{ 'link' | translate}})
        </a>
        <br>
        {{ 'requirement_tablet_android_version' | translate }}
        <br>
        {{ 'requirement_tablet_recommendation' | translate }}
        <a href="https://www.amazon.com/SAMSUNG-Android-Compact-Lasting-Battery/dp/B094Q89NKH/ref=sr_1_3?keywords=Samsung+galaxy+tab+a7+lite+8.7%27%27&qid=1688147565&sr=8-3" target="_blank">
            ({{ 'link' | translate}})
        </a>
    </div>
    <br>
    <div align="center">
        <span class="bold-text">
            2. {{ 'requirement_internet' | translate}}
        </span>
        <br>
        {{ 'requirement_internet_food_truck_line_1' | translate }}
        <a href="https://www.nradiowifi.net/article/6.html" target="_blank">
            ({{ 'link' | translate}})
        </a>
        <a href="https://www.amazon.com/NRadio-Portable-Unlocked-Vacation-Gathering/dp/B09PBP1F1F?ref_=ast_sto_dp" target="_blank">
            (Amazon)
        </a>
        <br>
        {{ 'requirement_internet_food_truck_line_2' | translate }}
        <br>
        {{ 'requirement_internet_food_truck_line_3' | translate }}
    </div>
    <br>
    <div align="center">
        <span class="bold-text">
            2. {{ 'requirement_printer' | translate}}
        </span>
        <br>
        <a href="https://star-emea.com/products/sp700/" target="_blank">
            Star Micronics SP 700 LAN
        </a>
        <a href="https://www.amazon.com/Star-Micronics-Ethernet-Auto-cutter-Internal/dp/B01DVEOLFG" target="_blank">
            (Amazon)
        </a>
        <br>
        <a href="https://star-emea.com/products/tsp100iv/" target="_blank">
            Star Micronics TSP100IV LAN
        </a>
        <a href="https://www.amazon.com/Micronics-TSP143IVUW-Direct-Thermal-Printer/dp/B0BG99F4R9" target="_blank">
            (Amazon)
        </a>
        <br>
        <a href="https://star-emea.com/products/tsp100/" target="_blank">
            Star Micronics TSP100III LAN
        </a>
        <a href="https://www.amazon.com/Star-Micronics-TSP143IIILAN-Ethernet-Auto-cutter/dp/B01HRJRXZQ" target="_blank">
            (Amazon)
        </a>
        <br>
        <a href="https://star-emea.com/products/tsp100/" target="_blank">
            Star Micronics TSP100IIIW Wi-Fi
        </a>
        <a href="https://www.amazon.com/Star-Micronics-TSP143IIIW-Wireless-Internal/dp/B01BJOFPNM" target="_blank">
            (Amazon)
        </a>
    </div>
    <br>
    <div align="center" class="equipment_lending-text palette-color-red">
        {{ 'equipment_lending' | translate}}
    </div>
</app-page>
