// Common application configuration for both dev and prod.
// For environment specific configuration use environments/environment.dev.ts or environments/environment.prod.ts
export const configuration = {
    facebookLink: 'https://www.facebook.com/WaiterNow',
    instagramLink: 'https://www.instagram.com/waiternow',
    youtubeLink: 'https://www.youtube.com/channel/UCekRkbAO2h1X2PxPWyOMoqw',
    twitterLink: 'https://twitter.com/WaiterNow',
    linkedinLink: 'https://www.linkedin.com/company/38099051',
    waiternowVideoEn: 'https://www.youtube.com/embed/huXXCTS_xT4',
    waiternowVideoEs: 'https://www.youtube.com/embed/addBF8TvULU',
};
