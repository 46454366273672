import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { LoggingService } from 'src/app/general/services/logging.service';
import { ProtoUtil } from '../util/proto-util';
import { Consumer, Runnable } from 'src/app/general/interfaces/functions';

export interface Version {
  currentBuildTime: Date
  currentVersion: string;
  latestBuildTime: Date;
  latestVersion: string;
  isVersionStale: boolean
}

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  private buildTime: string;
  private version?: Version;

  constructor(
        private backendService: BackendService,
        private loggingService: LoggingService) {
    // -------------------------------------------------------------------
    // DO NOT update manually. This is updated by the installation script.
    this.buildTime = '2024-11-10T19:29:02Z';
    // -------------------------------------------------------------------
  }

  public getVersion(onSuccess: Consumer<Version>, onError: Runnable) : void {
    if (this.version) {
      onSuccess(this.version);
      return;
    }
    this.backendService.getVersions(
      /* onSuccess */ response => {
        if (response && response.landingPageVersion) {
          const buildTimeDate = new Date(this.buildTime);
          const currentVersion = this.formatDateAsVersion(buildTimeDate);
          const latestBuildTime = new Date(ProtoUtil.timestampProtoToMillis(response.landingPageVersion.buildTime));
          const latestVersion = this.formatDateAsVersion(latestBuildTime);
          this.version = {
            currentBuildTime: buildTimeDate,
            currentVersion: currentVersion,
            latestBuildTime: latestBuildTime,
            latestVersion: latestVersion,
            isVersionStale: latestBuildTime > buildTimeDate
          };
          this.loggingService.logInfo('Version: ', this.version);
          onSuccess(this.version);
        }
      },
      /* onError */ error => {
        this.loggingService.logErrorWithCause(error, 'Error getting version');
        onError();
      }
    );
  }

  private formatDateAsVersion(date: Date) : string {
    return date.getFullYear().toString()
        + '.'
        + (date.getMonth() + 1).toString().padStart(2, '0')
        + '.'
        + date.getDate().toString().padStart(2, '0')
        + ' '
        + date.getHours().toString().padStart(2, '0')
        + '.'
        + date.getMinutes().toString().padStart(2, '0');
  }
}
