<table class="palette-background-color-black" width="100%">
    <tr>
        <td *ngIf="deviceService.isMobile()">
            <button mat-icon-button (click)="toggleSidebar()" class="palette-color-light-grey">
                <mat-icon>menu</mat-icon>
            </button>
        </td>
        <td>
            <div class="link-like"
                    (click)="navigationService.goToHomePage()"
                    [ngClass]="deviceService.ifMobile('logo-container-mobile').orElse('logo-container')">
                <img [ngClass]="deviceService.ifMobile('logo-image-mobile').orElse('logo-image')"
                        src="../../assets/img/logo-full.png"/>
            </div>
        </td>
        <td width="100%" style="vertical-align: bottom; padding-bottom: 10px;">
            <table *ngIf="!deviceService.isMobile()" align="center">
                <tr>
                    <td>
                        <button mat-raised-button class="header-button" (click)="navigationService.goToRequirementsPage()">
                            {{ 'requirements' | translate }}
                        </button>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td>
                        <button mat-raised-button class="header-button" [matMenuTriggerFor]="howItWorksMenu">
                            {{ 'how_it_works' | translate }}
                        </button>
                        <mat-menu #howItWorksMenu>
                            <button mat-menu-item (click)="navigationService.goToRestaurantPage()">
                                {{ 'business_type_restaurant' | translate }}
                            </button>
                            <button mat-menu-item (click)="navigationService.goToFoodTruckPage()">
                                {{ 'business_type_foodtruck' | translate }}
                            </button>
                        </mat-menu>
                    </td>
                   <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td>
                        <button mat-raised-button class="header-button" (click)="navigationService.goToPricingPage()">
                            {{ 'pricing' | translate }}
                        </button>
                    </td>
                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                    <td>
                        <button mat-fab extended class="palette-background-color-red" (click)="navigationService.goToBusinessWeb()">
                            {{ 'monitor_orders' | translate }}
                        </button>
                    </td>
                </tr>
            </table>
        </td>
        <td>
            <div [ngClass]="localizationService.isEnglish() ? 'language-button-on' : 'link-like language-button-off'"
                    (click)="localizationService.setEnglish()">
                EN
            </div>
        </td>
        <td>
            &nbsp;
        </td>
        <td>
            <div [ngClass]="localizationService.isSpanish() ? 'language-button-on' : 'link-like language-button-off'"
                    (click)="localizationService.setSpanish()">
                ES
            </div>
        </td>
        <td>
            &nbsp;&nbsp;&nbsp;&nbsp;
        </td>
    </tr>
</table>
