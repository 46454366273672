import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Country } from 'src/app/general/services/localization.service';
import { DeviceService } from '../../../general/services/device.service';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { HeadingComponent } from 'src/app/general/components/heading/heading.component';

@Component({
  selector: 'app-stripe-pricing',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatCardModule,
    MatDividerModule,
    HeadingComponent
  ],
  templateUrl: './stripe-pricing.component.html',
  styleUrls: ['./stripe-pricing.component.css']
})
export class StripePricingComponent {
  @Input() country!: Country;

  constructor(
      public deviceService: DeviceService) {
  }

  public isUs() {
    return this.country == Country.Us;
  }

  public isMexico() {
    return this.country == Country.Mexico;
  }
}
