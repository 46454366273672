<table width="100%" border="0" cellspacing="0" cellpadding="0">
    <tr>
        <td width="100%">
            <table width="100%" border="0" cellspacing="0" cellpadding="0">
                <tr>
                    <td class="rounded-corner-background-left">
                        <div class="rounded-corner-div"></div>
                    </td>
                    <td class="palette-background-color-less-black" width="100%">
                        <table align="center">
                            <!-- Top content goes here (content inside the rounded part of the footer) -->
                            <tr>
                                <td>
                                    <table align="center">
                                        <tr>
                                            <td><mat-icon svgIcon="facebook" (click)="goToFacebook()" class="palette-color-light-grey link-like"></mat-icon></td>
                                            <td><mat-icon svgIcon="instagram" (click)="goToInstagram()" class="palette-color-light-grey link-like"></mat-icon></td>
                                            <td><mat-icon svgIcon="youtube" (click)="goToYouTube()" class="palette-color-light-grey link-like"></mat-icon></td>
                                            <td><mat-icon svgIcon="twitter" (click)="goToTwitter()" class="palette-color-light-grey link-like"></mat-icon></td>
                                            <td><mat-icon svgIcon="linkedin" (click)="goToLinkedin()" class="palette-color-light-grey link-like"></mat-icon></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <table align="center">
                                        <tr>
                                            <td>
                                                <mat-icon fontIcon="email" class="palette-color-light-grey"></mat-icon>
                                            </td>
                                            <td class="palette-color-light-grey">
                                                wn@waiternow.com
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td class="rounded-corner-background-right">
                        <div class="rounded-corner-div"></div>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <tr>
        <td width="100%">
            <table class="palette-background-color-less-black" width="100%">
                <!-- Bottom content goes here (content below the rounded part of the footer) -->
                <tr>
                    <td align="center">
                        <a class="terms-privacy" [href]="getTermsLink()" target="_blank">{{ 'terms' | translate }}</a>
                        <span class="palette-color-light-grey">&nbsp;|&nbsp;</span>
                        <a class="terms-privacy" [href]="getPrivacyLink()" target="_blank">{{ 'privacy' | translate }}</a>
                    </td>
                </tr>
                <tr><td>&nbsp;</td></tr>
                <tr>
                    <td align="center" class="palette-color-light-grey powered-by">
                        Powered by Soft Evolution LLC
                    </td>
                </tr>
                <tr><td>&nbsp;</td></tr>
            </table>
        </td>
    </tr>
</table>
