import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { configuration } from 'src/app/configuration';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizationService } from 'src/app/general/services/localization.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatIconModule],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  constructor(
      private localizationService: LocalizationService) {
  }

  public goToFacebook(): void {
    window.open(configuration.facebookLink, "_blank");
  }

  public goToInstagram(): void {
    window.open(configuration.instagramLink, "_blank");
  }

  public goToYouTube(): void {
    window.open(configuration.youtubeLink, "_blank");
  }

  public goToTwitter(): void {
    window.open(configuration.twitterLink, "_blank");
  }

  public goToLinkedin(): void {
    window.open(configuration.linkedinLink, "_blank");
  }

  public getTermsLink(): string {
    if (this.localizationService.isSpanish()) {
      return 'terms-es.html'
    } else {
      return 'terms-en.html'
    }
  }

  public getPrivacyLink(): string {
    if (this.localizationService.isSpanish()) {
      return 'privacy-es.html'
    } else {
      return 'privacy-en.html'
    }
  }
}
