<table width="100%">
    <tr *ngFor="let rowInSequence of rowsSequence; let row = index;">
        <td *ngFor="let columnInSequence of columnsSequence; let column = index" [width]="columnWidth">
            <div *ngIf="isValidItem(row, column)" #internal_item_container>
                <!-- {{ getItemIndex(row, column) }} -->
                <!-- ng-content select cannot be bound, is for static content projection -->
                <!-- See https://github.com/angular/angular/issues/8563 -->
                <!-- <ng-content [select]="getItemSelectTag(row, column)"></ng-content> -->
            </div>
        </td>
    </tr> 
</table>
<!-- <ng-content></ng-content> -->
