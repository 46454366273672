import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { SidebarService } from '../../services/sidebar.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NavigationService } from '../../services/navigation.service';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from 'src/app/general/services/device.service';

@Component({
  selector: 'app-sidebar-content',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule
  ],
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.css']
})
export class SidebarContentComponent {

  languageToSwitchTo: string;

  constructor(
    public deviceService: DeviceService,
      private navigationService: NavigationService,
      private sidebarService: SidebarService,
      private localizationService: LocalizationService) {
    if (this.localizationService.isSpanish()) {
      this.languageToSwitchTo = 'English';
    } else {
      this.languageToSwitchTo = 'Español';
    }
  }

  public toggleLanguage(): void {
    if (this.localizationService.isSpanish()) {
      this.localizationService.setEnglish();
      this.languageToSwitchTo = 'Español';
    } else {
      this.localizationService.setSpanish();
      this.languageToSwitchTo = 'English';
    }
    this.toggleSidebar();
  }

  public toggleSidebar(): void {
    this.sidebarService.toggleSidebar();
  }

  goToPricingPage() {
    this.toggleSidebar();
    this.navigationService.goToPricingPage();
  }

  goToRequirementsPage() {
    this.toggleSidebar();
    this.navigationService.goToRequirementsPage();
  }

  goToFoodTruckPage() {
    this.toggleSidebar();
    this.navigationService.goToFoodTruckPage();
  }

  goToRestaurantPage() {
    this.toggleSidebar();
    this.navigationService.goToRestaurantPage();
  }

  public goToBusinessWeb(): void {
    this.toggleSidebar();
    this.navigationService.goToBusinessWeb();
  }
}
