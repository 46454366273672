import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { SidebarService } from '../../services/sidebar.service';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { NavigationService } from '../../services/navigation.service';
import { DeviceService } from 'src/app/general/services/device.service';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', '../../../../styles.css']
})
export class HeaderComponent {

  constructor(
      public deviceService: DeviceService,
      public localizationService: LocalizationService,
      public navigationService: NavigationService,
      private sidebarService: SidebarService) {
  }

  public toggleSidebar(): void {
    this.sidebarService.toggleSidebar();
  }
}
